import React from 'react';
import { FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { ValidationSchema } from '@models/common/validation';
import {
  PreOpeningFormValuesModel,
  PreOpeningPayload,
  UsePreOpeningHookReturnModel,
} from '@models/pre-opening';
import { errors } from '@utils/errors';

import { usePreOpeningSubmission } from '.';

const numberRegex = /^[0-9]/;

const usePreOpening = (): UsePreOpeningHookReturnModel => {
  const [mutate, result] = usePreOpeningSubmission({});

  const preOpeningForm: {
    initialValues: PreOpeningFormValuesModel;
    validationSchema: ValidationSchema<PreOpeningFormValuesModel>;
  } = React.useMemo(
    () => ({
      initialValues: {
        email: '',
        zipCode: '',
      },
      validationSchema: Yup.object({
        email: Yup.string()
          .email(errors.invalidInfo('Email'))
          .required(errors.invalidInfo('Email')),
        zipCode: Yup.string()
          .min(5, errors.invalidInfo('Zip Code'))
          .max(5, errors.invalidInfo('Zip Code'))
          .matches(numberRegex, errors.invalidInfo('Zip Code'))
          .required(errors.invalidInfo('Zip Code')),
      }) as ValidationSchema<PreOpeningFormValuesModel>,
    }),
    [],
  );

  const handleSubmit = React.useCallback(
    (
      values: PreOpeningFormValuesModel,
      formikHelpers?: FormikHelpers<PreOpeningFormValuesModel>,
    ) => {
      mutate(values as PreOpeningPayload, {
        onSuccess: () => formikHelpers?.resetForm(),
        onError: () => formikHelpers?.resetForm(),
      });
    },
    [mutate],
  );

  return React.useMemo(
    () => ({
      preOpeningForm,
      handleSubmit,
      result,
    }),
    [handleSubmit, preOpeningForm, result],
  );
};

export default usePreOpening;
