import styled from '@emotion/styled';

export const HomeSectionsWrap = styled.div<{
  zindex?: string;
  imgUrl?: string;
  mImgUrl?: string;
  bgPosition?: boolean;
}>`
  // height: calc(100vh + 30px);
  min-height:100vh;
  min-height: 778px;
 height: 100vh;

    background: url('/images/${props =>
      props.imgUrl
        ? props.imgUrl
        : 'home1preOpening.png'}') no-repeat  70% 100% / cover;
   
  display: flex;
  align-items: center;
  z-index: ${props => (props.zindex ? props.zindex : '1')};
  margin-top: -6vh;
  position: relative;

  :first-child {
   
    margin-top: 0px;
    // margin-top: 70px;
    // margin-top: 65px;
    @media (max-width: 599px) {
      background: 100%;
      height: 92vh;
      display: flex;
      justify-content: center;
      padding-bottom: unset;
      margin-top: 45px;
      background-size: cover;
    }
  }

  // :not(:first-child) {
  //   @media (max-width: 599px) {
  //     background: none;
  //   }
  }

  @media (max-width: 599px) {
    height: auto;
    min-height: auto;
    display: block;
    padding-bottom: 50px;
    background: none;
    margin-top:0;
    &.homeMobile{
      margin-top: -14vh;
      background: none;
    }
  },
`;

export const HomeSectionsPreWrap = styled.div<{
  zindex?: string;
  imgUrl?: string;
  mImgUrl?: string;
  bgPosition?: boolean;
  customHeight?: string;
}>`
  min-height: ${props => (props.customHeight ? props.customHeight : '840px')};
  height: auto;
  background-image: url('/images/${props =>
    props.imgUrl ? props.imgUrl : 'home1.png'}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom right;
  display: flex;
  align-items: center;
  z-index: ${props => (props.zindex ? props.zindex : 1)};
  margin-top: -6vh;
  position: relative;

  @media (max-width: 599px) {
    min-height: 650px;
  }

  &:first-child {
    min-height: 800px;
    // margin-top: 72px;
    overflow: hidden;
    @media screen and (min-width: 1550px) {
      min-height: 800px;
      height: 90vh;
    }

    @media (max-width: 1024px) {
      background: 100%;
      min-height: 100%;
      display: block;
      position: relative;
    }

    @media (max-width: 599px) {
      margin-top: 45px;
    }
  }

  @media (max-width: 1024px) {
    display: block;
    min-height: 600px;
    margin-top: 0px;
    background: none;
  }
`;

export const HomeCaptionsWrap = styled.div(
  ({ bgPush }: { bgPush?: string | undefined }) => ({
    width: '100%',
    maxWidth: '1100px',
    margin: 'auto',
    // paddingLeft: 22,
    // paddingRight: 22,
    paddingLeft: 6,
    paddingRight: 6,
    boxSizing: 'border-box',

    button: {
      '@media (max-width: 599px)': {
        fontSize: '15px',
        padding: '10px',
        letterSpacing: '1.1px',
      },
    },

    '@media screen and (min-width: 900px) and (max-width: 1100px)': {
      maxWidth: 960,
      minHeight: 450,
    },

    '@media (max-width: 599px)': {
      textAlign: 'center',
      maxWidth: '100%',
      padding: '0 20px',
      boxSizing: 'border-box',
      marginTop: bgPush || '0vh',
      // marginTop: '0vh',
      paddingTop: '0px',

      '> img': {
        height: '40px',
      },
    },
  }),
);

export const HomeCaptionsPreWrap = styled.div(
  ({ bgPush }: { bgPush?: string | undefined }) => ({
    width: '100%',
    maxWidth: 1180,
    margin: 'auto',
    paddingLeft: 40,
    paddingRight: 40,
    boxSizing: 'border-box',

    '@media (max-width: 1024px)': {
      textAlign: 'center',
      maxWidth: '100%',
      padding: '0px 20px 50px',
      boxSizing: 'border-box',
      marginBottom: bgPush || '0vh',
      paddingTop: '0px',
      alignItems: 'center',
    },
  }),
);

export const HomeCaptionsSubPreWrap = styled.div(
  ({
    bgPush,
    right,
  }: {
    bgPush?: string | undefined;
    right?: boolean | undefined;
  }) => ({
    width: '100%',
    maxWidth: 1180,
    margin: 'auto',
    paddingLeft: 40,
    paddingRight: 40,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: right ? 'flex-end' : 'flex-start',
    flexDirection: 'column',

    '@media (max-width: 1024px)': {
      textAlign: 'center',
      maxWidth: '100%',
      padding: '0 20px',
      boxSizing: 'border-box',
      marginTop: bgPush || '0vh',
      paddingTop: '0px',
      alignItems: 'center',
      paddingBottom: 40,
    },

    '@media (max-width: 599px)': {
      textAlign: 'center',
      maxWidth: '356px',
      padding: '0 20px',
      boxSizing: 'border-box',
      marginTop: bgPush || '0vh',
      paddingTop: '0px',
      paddingBottom: 40,
    },
  }),
);

export const GradientCaption = styled.div<{
  color?: string;
  notGradient?: boolean;
}>`
  font-size: 9vw;
  width: 100%;
  // font-size: clamp(49px, 9vw, 126px);
  font-size: clamp(49px, 10vw, 110px);
  line-height: 120%;
  letter-spacing: -0.15vw;
  letter-spacing: clamp(-1px, -0.15vw, -10px);
  color: ${props => (props.color ? props.color : '#B7E4F9')};
  background: ${props =>
    props.notGradient
      ? 'transparent'
      : 'linear-gradient(136.67deg, #FFC9C9 0%, #F4C9FF 40.41%, #B5E8FF 100%)'};
  -webkit-background-clip: ${props => (props.notGradient ? 'none' : 'text')};
  -webkit-text-fill-color: ${props =>
    props.notGradient ? 'none' : 'transparent'};
  font-family: Helvetica Neue;
  font-weight: bold;
  display: inline-block;
  // margin-top: 0px;
  // margin-top: -40px;

  @media screen and (min-width: 900px) and (max-width: 1280px) {
    font-size: 5vw;
    font-size: clamp(49px, 5vw, 85px);
    letter-spacing: -0.1vw;
    letter-spacing: clamp(-1px, -0.1vw, -10px);
  }

  @media (max-width: 599px) {
    width: 100%;
    margin-top: 0px;
  }
`;

export const GradientCaptionPre = styled.div<{
  color?: string;
  notGradient?: boolean;
  fontsize?: string;
  letterSpacing?: string;
  lineHeight?: string;
}>`
  font-size: 9vw;
  width: 100%;
  font-size: clamp(70px, 9vw, 109px);
  line-height: 120%;
  letter-spacing: -0.15vw;
  letter-spacing: clamp(-1px, -0.15vw, -10px);
  color: ${props => (props.color ? props.color : '#B7E4F9')};
  background: ${props =>
    props.notGradient
      ? 'transparent'
      : 'linear-gradient(136.67deg,#FFC9C9 0%,#F4C9FF 34.41%,#B5E8FF 62%)'};
  -webkit-background-clip: ${props => (props.notGradient ? 'none' : 'text')};
  -webkit-text-fill-color: ${props =>
    props.notGradient ? 'none' : 'transparent'};
  font-family: Helvetica Neue;
  font-weight: bold;
  display: inline-block;

  @media (max-width: 1024px) {
    font-size: clamp(50px, 6vw, 70px);
    background: ${props =>
      props.notGradient
        ? 'transparent'
        : 'linear-gradient(136.67deg,#FFC9C9 0%,#F4C9FF 65.41%,#B5E8FF 89%)'};
    -webkit-background-clip: ${props => (props.notGradient ? 'none' : 'text')};
    -webkit-text-fill-color: ${props =>
      props.notGradient ? 'none' : 'transparent'};
  }

  @media (max-width: 599px) {
    background: ${props =>
      props.notGradient
        ? 'transparent'
        : 'linear-gradient(136.67deg,#FFC9C9 0%,#F4C9FF 65.41%,#B5E8FF 89%)'};
    -webkit-background-clip: ${props => (props.notGradient ? 'none' : 'text')};
    -webkit-text-fill-color: ${props =>
      props.notGradient ? 'none' : 'transparent'};
    font-size: 47px;
    letter-spacing: -0.1vw;
    letter-spacing: clamp(-1px, -0.1vw, -10px);
    width: 100%;
    margin-top: 0px;
  }
`;
export const GradientCaptionPreTwo = styled.div<{
  color?: string;
  notGradient?: boolean;
}>`
  font-size: 9vw;
  width: 100%;
  font-size: clamp(64px, 9vw, 64px);
  line-height: 120%;
  letter-spacing: -0.15vw;
  letter-spacing: clamp(-1px, -0.15vw, -10px);
  color: ${props => (props.color ? props.color : '#B7E4F9')};
  background: ${props =>
    props.notGradient
      ? 'transparent'
      : 'linear-gradient(136.67deg,#FFC9C9 0%,#F4C9FF 34.41%,#B5E8FF 62%)'};
  -webkit-background-clip: ${props => (props.notGradient ? 'none' : 'text')};
  -webkit-text-fill-color: ${props =>
    props.notGradient ? 'none' : 'transparent'};
  font-family: Helvetica Neue;
  font-weight: bold;
  display: inline-block;

  @media (max-width: 1024px) {
    font-size: clamp(50px, 6vw, 70px);
    background: ${props =>
      props.notGradient
        ? 'transparent'
        : 'linear-gradient(136.67deg,#FFC9C9 0%,#F4C9FF 65.41%,#B5E8FF 89%)'};
    -webkit-background-clip: ${props => (props.notGradient ? 'none' : 'text')};
    -webkit-text-fill-color: ${props =>
      props.notGradient ? 'none' : 'transparent'};
  }

  @media (max-width: 599px) {
    background: ${props =>
      props.notGradient
        ? 'transparent'
        : 'linear-gradient(136.67deg,#FFC9C9 0%,#F4C9FF 65.41%,#B5E8FF 89%)'};
    -webkit-background-clip: ${props => (props.notGradient ? 'none' : 'text')};
    -webkit-text-fill-color: ${props =>
      props.notGradient ? 'none' : 'transparent'};
    font-size: 44px;
    letter-spacing: -0.1vw;
    letter-spacing: clamp(-1px, -0.1vw, -10px);
    width: 100%;
    margin-top: 0px;
  }
`;
export const GradientCaptionNegative = styled.div<{
  color?: string;
  notGradient?: boolean;
}>`
  font-size: 9vw;
  width: 100%;
  // font-size: clamp(49px, 9vw, 126px);
  font-size: clamp(49px, 9vw, 107px);
  line-height: 120%;
  letter-spacing: -0.15vw;
  letter-spacing: clamp(-1px, -0.15vw, -10px);
  color: ${props => (props.color ? props.color : '#B7E4F9')};
  background: ${props =>
    props.notGradient
      ? 'transparent'
      : 'linear-gradient(136.67deg,#FFC9C9 0%,#F4C9FF 25.41%,#B5E8FF 62%);'};

  // 'linear-gradient(136.67deg, #FFC9C9 0%, #F4C9FF 52.41%, #B5E8FF 100%)'};

  // background: linear-gradient(131.39deg, #FFC9C9 0%, #F4C9FF 52.41%, #B5E8FF 100%);

  -webkit-background-clip: ${props => (props.notGradient ? 'none' : 'text')};
  -webkit-text-fill-color: ${props =>
    props.notGradient ? 'none' : 'transparent'};
  font-family: Helvetica Neue;
  font-weight: bold;
  display: inline-block;
  margin-top: -30px;

  @media screen and (min-width: 900px) and (max-width: 1280px) {
    font-size: 5vw;
    font-size: clamp(49px, 5vw, 85px);
    letter-spacing: -0.1vw;
    letter-spacing: clamp(-1px, -0.1vw, -10px);
    margin-top: -15px;
  }

  @media (max-width: 599px) {
    width: 100%;
    margin-top: 0px;
  }
`;

export const GradientCaptionNegativePre = styled.div<{
  color?: string;
  notGradient?: boolean;
}>`
  font-size: 9vw;
  width: 100%;
  font-size: clamp(70px, 9vw, 109px);
  line-height: 120%;
  letter-spacing: -0.15vw;
  letter-spacing: clamp(-1px, -0.15vw, -10px);
  color: ${props => (props.color ? props.color : '#B7E4F9')};
  background: ${props =>
    props.notGradient
      ? 'transparent'
      : 'linear-gradient(136.67deg,#FFC9C9 0%,#F4C9FF 25.41%,#B5E8FF 62%);'};
  -webkit-background-clip: ${props => (props.notGradient ? 'none' : 'text')};
  -webkit-text-fill-color: ${props =>
    props.notGradient ? 'none' : 'transparent'};
  font-family: Helvetica Neue;
  font-weight: bold;
  display: inline-block;
  margin-top: -32px;

  @media (max-width: 1024px) {
    font-size: clamp(50px, 6vw, 70px);
    background: ${props =>
      props.notGradient
        ? 'transparent'
        : 'linear-gradient(136.67deg,#FFC9C9 0%,#F4C9FF 59.41%,#B5E8FF 85%)'};
    -webkit-background-clip: ${props => (props.notGradient ? 'none' : 'text')};
    -webkit-text-fill-color: ${props =>
      props.notGradient ? 'none' : 'transparent'};
    margin-top: -16px;
  }

  @media (max-width: 599px) {
    background: ${props =>
      props.notGradient
        ? 'transparent'
        : 'linear-gradient(136.67deg,#FFC9C9 0%,#F4C9FF 59.41%,#B5E8FF 85%)'};
    -webkit-background-clip: ${props => (props.notGradient ? 'none' : 'text')};
    -webkit-text-fill-color: ${props =>
      props.notGradient ? 'none' : 'transparent'};
    font-size: 47px;
    letter-spacing: -0.1vw;
    letter-spacing: clamp(-1px, -0.1vw, -10px);
    width: 100%;
    margin-top: -10px;
  }
`;

export const SolidCaption = styled.div<{
  color?: string;
  gradient?: boolean;
  notGradient?: boolean;
}>`
  font-size: 6.5vw;
  font-size: clamp(47px, 8vw, 110px);
  line-height: 95%;
  margin-top: 10px;
  letter-spacing: -0.15vw;
  letter-spacing: clamp(-1px, -0.15vw, -10px);
  font-family: Helvetica Neue;
  font-weight: bold;
  display: inline-block;
  color: ${props => (props.color ? props.color : '#B7E4F9')};
  background: ${props =>
    props.gradient
      ? 'transparent'
      : 'linear-gradient(136.67deg, #FFC9C9 0%, #F4C9FF 52.41%, #B5E8FF 100%)'};
  -webkit-background-clip: ${props => (props.notGradient ? 'none' : 'text')};
  -webkit-text-fill-color: ${props =>
    props.notGradient ? 'none' : 'transparent'};

  @media (max-width: 599px) {
    line-height: 100%;
  }
`;

export const SubCaption = styled.div<{
  color?: string;
  width?: string;
}>`
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.1px;
  line-height: 34px;
  margin-top: 20px;
  margin-bottom: 35px;
  @media screen and (min-width: 900px) and (max-width: 1100px) {
    font-size: 22px;
    line-height: 120%;
  }

  @media (max-width: 599px) {
    font-size: 18px;
    line-height: 27px;
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 900px) and (max-width: 1280px) {
    font-size: 20px;
    letter-spacing: 0.15px;
    line-height: 36px;
  }
`;

export const ImgWrapper = styled.div`
  display: none;

  @media (max-width: 599px) {
    display: block;
    padding-bottom: 30px;
  }
`;

export const ImgWrapperPre = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: block;
    padding-bottom: 0px;
  }
`;

export const LearnMore = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  padding-right: 40px;
  font-family: golden-plain;
  font-size: 39px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  font-weight: 300;

  * {
    font-family: inherit;
  }
  span {
    font-size: 39px;
    margin-right: 10px;
    margin-top: -3px;
  }

  @media (max-width: 599px) {
    top: 3vh;
    right: unset;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-right: 0px;
    width: 100%;
    text-align: center;
    justify-content: center;
    span {
      font-size: 25px;
    }
  }
`;

export const WaveWrap = styled.div`
  display: none;

  @media (max-width: 599px) {
    display: block;

    img {
      position: absolute;
      width: 100%;
      height: auto;
      bottom: 0px;
      left: 0;
      z-index: 6;
    }
  }
`;

export const NavHighLighter = styled.div`
  width: 100%;
  height: 140px;
  position: absolute;
  z-index: 9;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 30%,
    rgba(255, 255, 255, 1) 100%
  );

  @media (max-width: 599px) {
    display: none;
  }
`;

export const SliderIconWrap = styled.div`
  background-color: transparent;
  position: absolute;
  bottom: 6vh;
  left: 0;
  right: 0;
  max-width: 1100px;
  margin: auto;

  @media screen and (min-width: 900px) and (max-width: 1100px) {
    max-width: 920px;
  }

  @media (max-width: 599px) {
    bottom: 5vh;
  }
`;

export const MainHeroWrap = styled.div(
  ({ hide }: { hide: boolean | false }) => ({
    // height: 'calc(100vh + 30px)',
    width: hide ? '0vw' : '100vw',
    opacity: hide ? 0 : 1,
    overflow: 'hidden',
    transition: 'all 100ms linear',
  }),
);

export const MainHeroWrap4 = styled.div`
  width: 100%;
  opacity: 1;
  overflow: hidden;
  transition: all 100ms linear;
  position: relative;
`;

export const MainSliderWrap = styled.div(
  ({ hide }: { hide: boolean | false }) => ({
    width: hide ? '0vw' : '100vw',
    opacity: hide ? 0 : 1,
    overflow: 'hidden',
    transition: 'all 100ms linear',
  }),
);

export const MainHeroPreWrap = styled.div(
  ({ hide }: { hide: boolean | false }) => ({
    width: hide ? '0vw' : '100vw',
    opacity: hide ? 0 : 1,
    overflow: 'hidden',
    transition: 'all 100ms linear',
  }),
);

export const MainSliderPreWrap = styled.div(
  ({ hide }: { hide: boolean | false }) => ({
    width: hide ? '0vw' : '100vw',
    opacity: hide ? 0 : 1,
    overflow: 'hidden',
    transition: 'all 100ms linear',
  }),
);

export const FormikData = styled.div`
  display: grid;
  grid-template-columns: 293px 190px 183px;
  grid-gap: 15px;
  align-items: baseline;

  @media (max-width: 700px) {
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    grid-gap: 0px;
    grid-column-gap: 10px;
  }

  > div:first-child {
    @media (max-width: 700px) {
      grid-area: 1 / 1 / span 1 / span 2;
    }
  }
  > div:nth-child(2) {
    @media (max-width: 700px) {
      width: 162.5px;
    }
    @media (max-width: 370px) {
      width: 135px !important;
      input {
        padding-left: 16px;
      }
    }
  }

  > div {
    position: relative;

    div[role='alert'] {
      position: absolute;
      top: 90%;
      left: 20px;
      font-size: 16px;
    }
  }
  @media (max-width: 370px) {
    > div {
      position: relative;

      div[role='alert'] {
        left: 10px !important;
        font-size: 11px !important;
      }
    }
  }
  @media (max-width: 700px) {
    > div {
      position: relative;

      div[role='alert'] {
        position: absolute;
        top: 77%;
        left: 20px;
        font-size: 13px;
      }
    }
  }
`;

export const Updates = styled.div<{
  width?: string;
}>`
  font-family: proxima-nova, sans-serif;
  color: #152542;
  font-size: 23px;
  letter-spacing: 0.15px;
  line-height: 34px;
  margin-top: 23px;
  // padding-left: 5px;

  @media (max-width: 599px) {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 19px;
    padding-left: 0px;
  }
`;
export const UpdatesBold = styled.div<{
  fontSize?: string;
  textTransfrom?: string;
  MaxWidth?: string;
  margin?: string;
  display?: string;
}>`
  display: flex;
  font-family: proxima-nova, sans-serif;
  color: #152542;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.15px;
  line-height: 34px;
  margin-bottom: 29px;
  // padding-left: 5px;
  text-transfrom: normal;

  @media (max-width: 599px) {
    font-size: 18px;
    line-height: 27px;
   padding-left:0px;
   max-width: 350px;
   margin: 0 auto;
  display:inherit;
  }
  @media (min-width: 599px) and (max-width: 1024px) {
    font-size: 18px;
    line-height: 27px;
    padding-left: 0px;
    max-width: 380px;
    margin: 0 auto;
    display: inherit;
  }
 
  span {
    position: relative;
    font-family:arya-single, sans-serif;
    color: #a3adc0;
    ::before {
      content: '';
      // text-transform:
      height: 3px;
      width: 90%;
      background-color: #a3adc0;
      position: absolute;
      top: 45%;
      transform: rotate(-11deg);
      z-index: 1;
    }
    @media (max-width: 599px) {
    }
  }
    p{
      display: initial;
      letter-spacing: 1.75px;
      margin-bottom: 0;
      margin-top: 10px;
      font-family: arya-single,sans-serif;
      font-size: 22.5px;
      text-decoration: underline;
      text-underline-offset: 5px;
      @media (max-width: 992px) {
        font-size: 16px;
      }
    }
  }
`;

export const OpeningDate = styled.div`
  display: flex;
  align-items: center;
  font-family: proxima-nova, sans-serif;
  color: #152542;
  font-size: 20.9px;
  font-weight: 600;
  letter-spacing: 1.74px;
  line-height: 28.5px;
  margin-top: 49px;

  @media (max-width: 599px) {
    max-width: 221px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    font-size: 12px;
    letter-spacing: 1.16px;
    line-height: 20px;
    font-weight: 700;
    // margin-top: 10px;
    margin-bottom: 0px;
  }
  @media (min-width: 599px) and (max-width: 1024px) {
    max-width: 314px;
  }
`;
export const Address = styled.div`
  color: #152542;
  font-family: 'Proxima Nova';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28.5px;
  padding-top: 5px;
  @media (max-width: 599px) {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    font-size: 11px;
    letter-spacing: 1.16px;
    line-height: 20px;
  }
`;

export const LearnMoreNew = styled.div`
  display: flex;
  align-items: center;
  padding-right: 40px;
  font-family: golden-plain;
  font-size: 37.05px;
  line-height: 19.95px;
  text-align: center;
  cursor: pointer;
  font-weight: normal;
  transform: scale(0.9);
  a {
    color: inherit;
    text-decoration: none;
    span {
      font-size: 37.05px;
      margin-right: 10px;
      margin-top: -3px;
    }
    img {
      position: relative;
      top: 3px;
      transform: scale(1.1);
    }
  }
  * {
    font-family: inherit;
  }

  @media (max-width: 1024px) {
    padding-top: 10px;
    padding-right: 0px;

    span {
      font-size: 25px;
    }

    a img {
      position: relative;
      top: 2px;
      transform: scale(0.9);
    }
  }

  @media (max-width: 600px) {
    padding-top: 0px;
    padding-right: 0px;

    span {
      font-size: 25px;
    }

    a img {
      position: relative;
      top: 2px;
      transform: scale(0.9);
    }
  }
`;

export const SuccessWrapper = styled.div`
  margin-top: 20px;
  // min-height: 100px;

  @media (max-width: 599px) {
    min-height: 250px;
    max-width: 226px;
    margin: auto;
    display: grid;
    place-items: center;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  margin-top: 70px;
  // margin-top: 12.4vh;
  @media (max-width: 599px) {
    margin-top: 0;
  }
`;

export const TextCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const SpacingTop = styled.div`
  // margin-top: -50px;

  @media (max-width: 1024px) {
    margin-top: 0px;
    position: absolute;
    top: 32px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 550px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;
export const RightData = styled.div`
  alignitems: flex-end;
  margin-top: -27px;
  @media (max-width: 599px) {
    margin-top: 0px;
  }
`;
export const IframeData = styled.div`
  margin-top: 2.25rem;
  width: 735px;
  height: 405px;
  @media (max-width: 599px) {
    width: initial;
    margin-top: 38px;
  }
`;

export const BookingOffer = styled.div`
  max-width: 740px;
  font-size: 22.5px;
  @media (max-width: 599px) {
    max-width: 100%;
    margin-top: 20px;
  }
`;

export const LearnMorePhase3 = styled.div`
  display: flex;
  margin-top: 21px;
  position: relative;
  left: -8px;
  @media (max-width: 599px) {
    margin-top: 0px;
  }
  @media (max-width: 1024px) {
    left: 0px;
  }
`;

export const Phase3Updates = styled.div`
  padding-top: 10px;
  @media (max-width: 599px) {
    margin-top: 25px;
    padding: 0 20px;
  }
  UpdatesBold {
    @media (max-width: 599px) {
      max-width: 100%;
      padding: 0 20px;
      margin-top: 20px;
    }
  }
`;

export const MobileBtn = styled.div`
  @media (max-width: 599px) {
    max-width: 177px;
    margin: 0 auto;
  }
  button {
    max-height: 45px;
    -webkit-text-fill-color: #fff;

    @media (max-width: 599px) {
      max-height: 35px;
      font-size: 15px;
      line-height: 13px;
    }
  }
`;
export const GradientIpad = styled.div`
  background: linear-gradient(
    130deg,
    #b5e8ff0a 0%,
    #b5e8ff 52.41%,
    #b5e8ff 100%
  );
  opacity: 0.2;
  @media (min-width: 320px) and (max-width: 370px) {
    height: 80px;
  }
  @media (min-width: 699px) and (max-width: 820px) {
    height: 45px;
  }
`;
export const BeniftsPerks = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (max-width: 992px) {
    align-items: center;
  }
`;
export const PerksHeading = styled.div`
  font-family: arya-single, sans-serif;
  font-size: 22.5px;
  text-transform: uppercase;
  letter-spacing: 2.7px;
  padding: 0px 0 47px 0;
  @media (max-width: 992px) {
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 26px;
    padding: 35px 0 28px 0;
    max-width: 235px;
    padding: 20px 0 46px 0;
  }
`;
export const PerksWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 58px;
  align-items: flex-start;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    grid-gap: 26px;
  }
`;
export const PerksList = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 72px;
    height: 72px;
    margin-right: 20px;
    @media (max-width: 992px) {
      width: 50px;
      height: 50px;
    }
  }
  span {
    color: #172643;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.24px;
    line-height: 28px;
    @media (max-width: 599px) {
      line-height: 24px;
    }
  }
`;

export const GroupedButtonGrey = styled.button<{
  pb?: string;
  pt?: string;
  pr?: string;
  pl?: string;
}>`
  padding-top: ${props => (props.pt ? props.pt : '8px')};
  padding-right: ${props => (props.pr ? props.pr : '20px')};
  padding-bottom: ${props => (props.pb ? props.pb : '8px')};
  padding-left: ${props => (props.pl ? props.pl : '30px')};
  border: 0;
  border-bottom: 0;
  cursor: pointer;
  letter-spacing: 2.56px;
  font-size: 23px;
  font-weight: 600;
  line-height: 28px;
  text-transform: uppercase;
  font-family: proxima-nova, sans-serif;
  background: #172643;
  display: flex;
  align-items: center;
  text-transfrom: uppercase;
  color: #fff;
  border-radius: 30px;
`;
export const GroupedButtonGreyOpen = styled.button<{
  pb?: string;
  pt?: string;
  pr?: string;
  pl?: string;
}>`
  padding-top: ${props => (props.pt ? props.pt : '8px')};
  padding-right: ${props => (props.pr ? props.pr : '20px')};
  padding-bottom: ${props => (props.pb ? props.pb : '8px')};
  padding-left: ${props => (props.pl ? props.pl : '20px')};
  border: 0;
  border-bottom: 0;
  cursor: pointer;
  letter-spacing: 2.56px;
  font-size: 23px;
  font-weight: 600;
  line-height: 28px;
  text-transform: uppercase;
  font-family: proxima-nova, sans-serif;
  background: none;
  display: flex;
  align-items: center;
  text-transfrom: uppercase;
  color: #fff;
  width: 220px;
  justify-content: center;
`;
