import React from 'react';
import { useField } from 'formik';

import { ErrorMessage } from '@components/shared/error';
import InputWrapper from '@components/shared/form/input-wrapper';
import StyledInput from '@components/shared/input';
import Label from '@components/shared/label';
import { ErrorMessageError as E } from '@models/common/error';
import { FormInputProps } from '@models/common/form';

const Input: React.FunctionComponent<FormInputProps> = ({
  label,
  containerStyles,
  rounded,
  small,
  placeholder = '',
  ...rest
}) => {
  const [field, meta] = useField(rest);
  const { name } = field;

  return (
    <InputWrapper css={[containerStyles]}>
      {label && (
        <Label rounded={rounded} small={small} label={label} name={name} />
      )}
      <StyledInput
        rounded={rounded}
        small={small}
        id={name}
        type="text"
        {...field}
        {...rest}
        placeholder={placeholder}
      />
      {meta.touched && meta.error && <ErrorMessage error={meta.error as E} />}
    </InputWrapper>
  );
};

export default Input;
