import styled from '@emotion/styled';

const StyledInput = styled.input<{ rounded?: boolean; small?: boolean }>`
  font-size: ${props => (props.small ? '16px' : '20px')};
  font-family: proxima-nova, sans-serif;
  letter-spacing: 0.17px;
  line-height: ${props => (props.small ? '18px' : '24px')};
  font-weight: 500;
  border: ${props => (props.rounded ? '1px solid #CACACA' : '0px')};
  border-radius: ${props => (props.rounded ? '19.5px' : '0px')};
  border-bottom: ${props =>
    props.rounded ? '1px solid #CACACA' : '1px solid #172643'};
  padding: ${props =>
    props.rounded ? '10px 0px 10px 22px' : '5px 0px 5px 1px'};

  ::placeholder {
    font-weight: 300;
  }
`;

export default StyledInput;
