import React from 'react';

import ModalHeader from '@components/modal/modal-header';
import { Button } from '@components/shared/button';
import { GridTwoEqual, MediumText } from '@components/shared/common';
import {
  AlertModalBodyWrapper,
  StyledAlertModal,
} from '@components/shared/modal';
import { AlertModalModel } from '@models/common/modal';

const AlertModal: React.FunctionComponent<AlertModalModel> = ({
  isOpen,
  header,
  contents,
  cancel,
  confirm,
  confirmText,
  cancelText,
  showCloseButton = false,
  handleClose,
  buttonMaxWidth = 250,
  secondLineContent = null,
  contentMaxWidth = '100%',
  isMinWidthRequired = false,
}) => (
  <StyledAlertModal isOpen={isOpen}>
    {header ? (
      <ModalHeader
        title={header}
        showCloseButton={showCloseButton}
        closeModal={handleClose}
      />
    ) : (
      <></>
    )}
    <AlertModalBodyWrapper>
      <MediumText
        color="#737373"
        fWeight="300"
        align="center"
        data-testid="modal-content"
        style={{
          maxWidth: `${contentMaxWidth}`,
          minWidth: `${isMinWidthRequired ? '400px' : '0px'}`,
        }}
      >
        {contents}
      </MediumText>
      {secondLineContent && (
        <MediumText
          color="#737373"
          fWeight="300"
          align="center"
          data-testid="modal-content"
        >
          {secondLineContent}
        </MediumText>
      )}
      {cancelText ? (
        <GridTwoEqual css={{ marginBottom: 0, gridGap: 15 }}>
          <Button type="button" variant="outline" onClick={cancel}>
            {cancelText}
          </Button>
          <Button
            type="button"
            css={{
              paddingLeft: 5,
              paddingRight: 5,
            }}
            onClick={confirm}
          >
            {confirmText}
          </Button>
        </GridTwoEqual>
      ) : (
        <div
          css={{
            textAlign: 'center',
            maxWidth: buttonMaxWidth,
            margin: 'auto',
            paddingTop: 30,
            minWidth: `${isMinWidthRequired ? '300px' : '0px'}`,
          }}
        >
          <Button type="button" onClick={confirm}>
            {confirmText}
          </Button>
        </div>
      )}
    </AlertModalBodyWrapper>
  </StyledAlertModal>
);

export default AlertModal;
