import React from 'react';
import { NextPage } from 'next';

import Footer from '@components/footer';
import Header from '@components/header';
import Home from '@components/home';
import Meta from '@components/meta-tag';
import PreOpening from '@components/pre-opening';
import useStaticPageSegment from '@services/analytics/use-analytics';
import ThankYou from '@components/thank-you';

const preOpeningPhase: string | undefined =
  process.env.NEXT_PUBLIC_PRE_OPENING_PHASE;

const HomePage: NextPage = () => {
  const [transparentHeader, setTransparentHeader] = React.useState(true);
  const { trackStaticPageEvents } = useStaticPageSegment();
  const showThankYouPage = true;

  React.useEffect(() => {
    trackStaticPageEvents('Home');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = React.useCallback(() => {
    const pageScrollPosition = window.pageYOffset;
    if (pageScrollPosition > 70) {
      setTransparentHeader(false);
    } else {
      setTransparentHeader(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    window?.addEventListener('scroll', handleScroll);

    return () => {
      window?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      {showThankYouPage ? (
        <div>
          <ThankYou />
        </div>
      ) : (
        <>
          <Meta titleSuffix="Home">
            <meta
              name="keywords"
              content="yoga classes, yoga studio, yoga classes near me, yoga workshops, infrared sauna, sauna"
            />
          </Meta>
          {!preOpeningPhase && (
            <Header
              homePageStyles={transparentHeader}
              showBookButton
              showMenuOption
            />
          )}
          {preOpeningPhase ? (
            <PreOpening preOpeningPhaseNumber={Number(preOpeningPhase)} />
          ) : (
            <Home />
          )}
          {!preOpeningPhase && <Footer large />}
        </>
      )}
    </>
  );
};
export default HomePage;
