import { PreOpeningActionTypes } from '@models/pre-opening';

import { makeActionCreator } from './index';

const setIsCardAdded = makeActionCreator<
  PreOpeningActionTypes.SET_IS_CARD_ADDED,
  boolean
>(PreOpeningActionTypes.SET_IS_CARD_ADDED);

const setIsFoundingMembershipPurchased = makeActionCreator<
  PreOpeningActionTypes.SET_IS_FOUNDING_MEMBERSHIP_PURCHASED,
  boolean
>(PreOpeningActionTypes.SET_IS_FOUNDING_MEMBERSHIP_PURCHASED);

const setIsMembershipAlreadyPurchased = makeActionCreator<
  PreOpeningActionTypes.SET_IS_MEMBERSHIP_ALREADY_PURCHASED,
  boolean
>(PreOpeningActionTypes.SET_IS_MEMBERSHIP_ALREADY_PURCHASED);

export {
  setIsCardAdded,
  setIsFoundingMembershipPurchased,
  setIsMembershipAlreadyPurchased,
};
