import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Form, Formik } from 'formik';

import { setIsMembershipAlreadyPurchased } from '@actions/pre-opening';
import Footer from '@components/footer';
import Input from '@components/form/input';
import HeaderNavigation from '@components/header';
import {
  Address,
  BeniftsPerks,
  BookingOffer,
  FormikData,
  GradientCaptionNegativePre,
  GradientCaptionPre,
  HomeCaptionsPreWrap,
  HomeCaptionsSubPreWrap,
  HomeSectionsPreWrap,
  IframeData,
  ImgWrapperPre,
  LearnMoreNew,
  LearnMorePhase3,
  MobileBtn,
  OpeningDate,
  PerksHeading,
  PerksList,
  PerksWrapper,
  Phase3Updates,
  SolidCaption,
  SpacingTop,
  SubCaption,
  SuccessWrapper,
  TextCenter,
  Updates,
  UpdatesBold,
} from '@components/home/home.styled';
import { PngIcon, SvgIcon } from '@components/icon';
import Meta from '@components/meta-tag';
import AlertModal from '@components/modal/alert-modal';
import { Button } from '@components/shared/button';
import {
  CustomText,
  HideDesktop,
  HideIpad,
  HideIpadCustom,
  HideIpadCustom2,
  HideMobile,
  HideSmallScreens,
  ShowMobile,
  ShowMobileCustom,
  ShowMobileCustom2,
  ShowSmallScreens,
  StyledLinkButton,
} from '@components/shared/common';
import { FullPageLoader } from '@components/shared/loader';
import {
  usePreopeningDispatch,
  usePreopeningState,
} from '@context/pre-opening';
import { PreOpeningComponentProps } from '@models/common/pre-opening';
import useStaticPageSegment from '@services/analytics/use-analytics';
import usePreOpening from '@services/pre-opening/use-preopening';
import { useFetchUserCards } from '@services/user';
import * as colors from '@styles/colors';
import { transparent } from '@styles/colors';

const PreOpening: React.FunctionComponent<PreOpeningComponentProps> = props => {
  const router = useRouter();
  // const formRef: React.RefObject<HTMLDivElement> = React.useRef(null);

  const { trackStaticPageEvents } = useStaticPageSegment();
  const [isFormTouched, setIsFormTouched] = useState(false);
  const { preOpeningPhaseNumber } = props;

  const { preOpeningForm, handleSubmit, result } = usePreOpening();
  // const { trackStaticPageEvents } = useStaticPageSegment();
  const preOpeningDispatch = usePreopeningDispatch();
  const { isFoundingMembershipPurchased } = usePreopeningState();

  const { isMembershipAlreadyPurchased } = usePreopeningState();
  const [showModel, setShowModel] = useState(isMembershipAlreadyPurchased);

  React.useEffect(() => {
    trackStaticPageEvents('Learn More');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data } = useFetchUserCards();
  const isJoinNowButtonDisabledForUser = data && data.membership.length > 0;

  const onJoinNowButtonClick = () => {
    if (data) {
      if (data.card.length === 0) {
        router.push('/become-a-member/payment');
      } else if (data.membership.length === 0) {
        router.push('/become-a-member/confirm-purchase');
      }
    } else {
      router.push('/register');
    }
  };

  const preLoadingForm = () => (
    <Formik
      initialValues={preOpeningForm.initialValues}
      validationSchema={preOpeningForm.validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, touched }) => {
        setIsFormTouched(!!(touched.email || touched.zipCode));
        return (
          <Form>
            <FormikData>
              <Input
                rounded
                small
                containerStyles={{ marginBottom: 10 }}
                name="email"
                label=""
                placeholder="Enter email"
                css={{ background: transparent }}
              />
              <Input
                rounded
                small
                containerStyles={{ marginBottom: 10 }}
                name="zipCode"
                label=""
                value={
                  values.zipCode.trim().length <= 5 &&
                  !Number.isNaN(Number(values.zipCode.trim()) && values.zipCode)
                    ? values.zipCode
                    : ''
                }
                placeholder="Enter zip code"
                type="text"
                maxLength={5}
                css={{ background: transparent }}
              />
              <Button
                btnSize="small"
                css={{
                  maxWidth: 300,
                  width: '100%',
                  height: 40,
                }}
                variant={
                  preOpeningPhaseNumber === 3 ? 'gradient3' : 'gradient1'
                }
                type="submit"
              >
                submit
              </Button>
            </FormikData>
          </Form>
        );
      }}
    </Formik>
  );

  const renderSuccessMessage = () => (
    <SuccessWrapper>
      <UpdatesBold>
        Thank&apos;s! We&apos;ll be in touch soon if you&apos;re a winner!
      </UpdatesBold>
    </SuccessWrapper>
  );

  const handleScroll = () => {};

  const renderErrorMessage = (error: string) => (
    <div
      role="alert"
      css={[
        {
          color: colors.danger,
          textAlign: 'left',
          marginTop: 5,
          fontSize: 18,
          fontWeight: 600,
          letterSpacing: 0.2,
          lineHeight: '30px',
        },
      ]}
    >
      {error}
    </div>
  );

  const renderLearnMoreButton = () => (
    <LearnMoreNew
      onClick={() => {
        handleScroll();
      }}
    >
      <a href="#infraredPage">
        <span>learn more</span>
        <SvgIcon src="downarrow.svg" />
      </a>
    </LearnMoreNew>
  );

  const renderLearnMoreContent = () => (
    <>
      <OpeningDate>
        OPENING SPRING 2023 IN CULVER CITY{' '}
        <HideSmallScreens css={{ paddingLeft: 5 }}>|</HideSmallScreens>
        <HideSmallScreens>{renderLearnMoreButton()}</HideSmallScreens>
      </OpeningDate>
      <Address>11924 Washington Blvd, Suite A, Los Angeles CA 90066</Address>
    </>
  );

  const handleConfirm = () => {
    setShowModel(false);
    preOpeningDispatch(setIsMembershipAlreadyPurchased(false));
  };

  const renderAccountAlreadyPurchacedMembershipModel = () => (
    <AlertModal
      isOpen={showModel}
      contents="You already signed up for a founding membership. We’ll notify you as
      soon as our books are open and your membership is activated. See you
      on the Brightside!"
      header="Uh OH!"
      confirm={handleConfirm}
      confirmText="Okay"
    />
  );

  const renderPhase1PreliveSection = () => (
    <>
      <HomeSectionsPreWrap zindex="6" imgUrl="home1preOpening.png">
        <HideIpad>
          <ImgWrapperPre>
            <PngIcon width="100%" src="tab-home-slide-1new.png" />
          </ImgWrapperPre>
        </HideIpad>
        <ShowMobile>
          <ImgWrapperPre>
            <PngIcon width="100%" src="mob-home-slide-1new.png" />
          </ImgWrapperPre>
        </ShowMobile>

        <HomeCaptionsPreWrap css={{ display: 'flex', flexDirection: 'column' }}>
          <SpacingTop>
            <GradientCaptionPre>a brighter</GradientCaptionPre>
            <GradientCaptionNegativePre>
              take on yoga
            </GradientCaptionNegativePre>
            <GradientCaptionNegativePre>+ saunas</GradientCaptionNegativePre>
            <ShowSmallScreens>
              <LearnMoreNew
                onClick={() => {
                  handleScroll();
                }}
              >
                <a href="#infraredPage">
                  <span>learn more</span>
                  <SvgIcon src="downarrow.svg" />
                </a>
              </LearnMoreNew>
            </ShowSmallScreens>
          </SpacingTop>
          {!result.isSuccess && (
            <div>
              <Updates>
                The first 100 to sign up for updates, special offers and
                giveaways
              </Updates>
              <UpdatesBold>
                win a free yoga class and infrared sauna session!
              </UpdatesBold>
            </div>
          )}

          {result.isSuccess ? renderSuccessMessage() : preLoadingForm()}
          {result.isError &&
            !isFormTouched &&
            renderErrorMessage('This email address is already registered')}
          {renderLearnMoreContent()}
        </HomeCaptionsPreWrap>
      </HomeSectionsPreWrap>
    </>
  );

  const renderPhase2PreliveSection = () => (
    <>
      <HomeSectionsPreWrap zindex="6" imgUrl="pre-opening2.png">
        <HideIpad>
          <ImgWrapperPre>
            <PngIcon width="100%" src="tab-home-slide-1new.png" />
          </ImgWrapperPre>
        </HideIpad>
        <ShowMobile>
          <ImgWrapperPre>
            <PngIcon width="100%" src="mob-home-slide-1new.png" />
          </ImgWrapperPre>
        </ShowMobile>

        <HomeCaptionsPreWrap css={{ display: 'flex', flexDirection: 'column' }}>
          <SpacingTop>
            <GradientCaptionPre>3 months of free yoga?</GradientCaptionPre>
            <GradientCaptionPre>no sweat!</GradientCaptionPre>

            <ShowSmallScreens>
              <LearnMoreNew
                onClick={() => {
                  handleScroll();
                }}
              >
                <a href="#infraredPage">
                  <span>learn more</span>
                  <SvgIcon src="downarrow.svg" />
                </a>
              </LearnMoreNew>
            </ShowSmallScreens>
            <IframeData>
              <iframe
                frameBorder="0"
                title="iframe"
                src={process.env.VIRAL_SWEEP_URL}
                height="100%"
                width="100%"
              >
                Not Supported
              </iframe>
            </IframeData>
          </SpacingTop>
        </HomeCaptionsPreWrap>
      </HomeSectionsPreWrap>

      {renderLearnMoreContent()}
    </>
  );

  const renderPhase3PreliveSection = () => (
    <>
      <div className="joindate">Opening July 2023 in Culver City!</div>
      <HomeSectionsPreWrap
        className="hide-overflow phase3firstsection"
        zindex="6"
        imgUrl="Heronew-3-transaprent.png"
      >
        <PngIcon className="yoga-woman " src="YogaPose_1_Water.png" />
        <HideIpadCustom>
          <ImgWrapperPre>
            <PngIcon
              width="100%"
              src="Heronew-3-ipad.png"
              style={{
                maxHeight: 900,
                objectFit: 'cover',
                objectPosition: 'bottom',
                height: 900,
                // marginTop: '18px',
              }}
            />
          </ImgWrapperPre>
        </HideIpadCustom>
        <HideIpadCustom2>
          <ImgWrapperPre>
            <PngIcon
              width="100%"
              src="Heronew-3-ipad.png"
              style={{
                maxHeight: 1000,
                objectFit: 'cover',
                objectPosition: 'bottom',
                height: 1000,
                marginTop: '-35px',
              }}
            />
          </ImgWrapperPre>
        </HideIpadCustom2>
        <ShowMobileCustom>
          <ImgWrapperPre>
            <PngIcon
              width="100%"
              src="Heronew-3-mob.png"
              style={{
                height: 930,
                objectFit: 'cover',
                objectPosition: 'bottom',
                marginTop: '-40px',
              }}
            />
          </ImgWrapperPre>
        </ShowMobileCustom>
        <ShowMobileCustom2>
          <ImgWrapperPre>
            <PngIcon
              width="100%"
              src="Heronew-3-mob.png"
              style={{
                maxHeight: 1070,
                objectFit: 'cover',
                objectPosition: 'bottom',
                height: 1070,
                marginTop: '-40px',
              }}
            />
          </ImgWrapperPre>
        </ShowMobileCustom2>
        <div className="curve-img" />

        <HomeCaptionsPreWrap
          className="phase3-icon"
          css={{ display: 'flex', flexDirection: 'column' }}
        >
          <SpacingTop css={{ marginTop: '-70px' }}>
            <GradientCaptionPre
              css={{
                fontSize: 'clamp(80px, 9vw, 80px)',
                letterSpacing: 'clamp(0px, -0.15vw, -10px);',
                lineHeight: '127%',

                WebkitBackgroundClip: 'text',
                color: '#172643!important',
                WebkitTextFillColor: '#172643!important',
              }}
            >
              <div className="desktop-show">a brighter take</div>
              <div className="desktop-hide">a brighter </div>
            </GradientCaptionPre>
            <GradientCaptionNegativePre
              css={{
                fontSize: 'clamp(80px, 9vw, 80px)',
                letterSpacing: 'clamp(0px, -0.15vw, -10px);',
                lineHeight: '127%',
                color: '#172643 !important',
                WebkitTextFillColor: '#172643!important',
                marginTop: '-7px',
                // -webkit-text-fill-color: transparent;
              }}
            >
              <div className="desktop-show"> on yoga & saunas</div>
              <div className="desktop-hide">take on yoga </div>
              <div className="desktop-hide" css={{ marginTop: '-12px' }}>
                & saunas
              </div>
            </GradientCaptionNegativePre>
            <div className="btn-members">
              <span
                css={{
                  fontFamily: 'arya-single, sans-serif',
                }}
              >
                ONLY 50 SPOTS AVAILABLE!
              </span>
            </div>
            <Phase3Updates className="margin0">
              <UpdatesBold
                className="mobile-hide font300"
                MaxWidth="100%"
                margin=""
                display="flex"
                css={{
                  display: 'unset',
                  textTransform: 'uppercase',
                  letterSpacing: '1.75px',
                  marginBottom: '0',
                  fontFamily: 'arya-single, sans-serif',
                  fontSize: '22.5px',
                }}
              >
                {/* <span>$199 </span> */}
                30% off an unlimited yoga membership <p>for life</p>
              </UpdatesBold>

              <UpdatesBold
                className="mobile-show margin0"
                MaxWidth="100%"
                margin=""
                display="flex"
                css={{
                  display: 'unset',
                  textTransform: 'uppercase',
                  letterSpacing: '1.75px',
                  marginBottom: '0',
                  marginTop: '10px',
                  fontFamily: 'arya-single, sans-serif',
                  fontSize: '22.5px',
                }}
              >
                {/* <span>$199 </span> */}
                30% off <p>for life</p> <div>unlimited yoga membership</div>
              </UpdatesBold>
              {renderAccountAlreadyPurchacedMembershipModel()}
              <BookingOffer>
                <Updates
                  className="margin20"
                  css={{
                    fontSize: '22.5px',
                    marginTop: '17px',
                    marginBottom: '25px',
                  }}
                >
                  Only $139/mo ($199 value)
                  <span className="mobile-hide ">
                    . &nbsp;You won’t be charged until we open.
                  </span>
                </Updates>
              </BookingOffer>

              <MobileBtn>
                <Button
                  className="join-gradient"
                  // btnSize="small"
                  css={{
                    maxWidth: 346,
                    width: '100%',
                    height: 45,
                    fontSize: 20,
                    fontWeight: 600,
                  }}
                  disabled={!!isJoinNowButtonDisabledForUser}
                  type="submit"
                  onClick={() => onJoinNowButtonClick()}
                >
                  {isJoinNowButtonDisabledForUser ||
                  isFoundingMembershipPurchased ? (
                    <span>You’re in!</span>
                  ) : (
                    <span>RESERVE NOW</span>
                  )}
                </Button>
              </MobileBtn>
              <span className="mobile-show ">
                You won’t be charged until we open.
              </span>
            </Phase3Updates>
          </SpacingTop>
          <div className="opening-section">
            <BeniftsPerks>
              <PerksHeading>MEMBERSHIP benefits and perks</PerksHeading>
              <PerksWrapper>
                <PerksList className="align-center">
                  <PngIcon
                    width="100%"
                    src="membership-yoga.png"
                    alt="membership-yoga"
                  />
                  <CustomText>
                    Unlimited yoga <div> classes per month</div>
                  </CustomText>
                </PerksList>

                <PerksList className="align-center">
                  <PngIcon
                    width="100%"
                    src="membership-cancel.png"
                    alt="membership-cancel"
                  />
                  <CustomText>
                    No obligation, month to<div> month, cancel anytime</div>
                  </CustomText>
                </PerksList>
                <PerksList className="align-center">
                  <PngIcon
                    width="100%"
                    src="membership-credits.png"
                    alt="membership-credits"
                  />
                  <CustomText>
                    Get 3 guest credits per
                    <div>month to share with friends</div>
                  </CustomText>
                </PerksList>
                <PerksList className="align-center">
                  <PngIcon
                    width="100%"
                    src="membership-gift.png"
                    alt="membership-gift"
                  />
                  <CustomText>
                    Exclusive Brightside member gift
                    <div> (provided at your first class)</div>
                  </CustomText>
                </PerksList>
                <PerksList className="align-center">
                  <PngIcon
                    width="100%"
                    src="membership-percentage.png"
                    alt="membership-percentage"
                  />
                  <CustomText>10% off retail items</CustomText>
                </PerksList>
                <PerksList className="align-start">
                  <PngIcon
                    width="100%"
                    src="membership-heart.png"
                    alt="membership-heart"
                  />
                  <CustomText>
                    For every membership sold,
                    <div> Brightside will help provide an</div>
                    <div>hour of physical activity to a child</div>in an
                    underserved community
                    <div>through Action for Healthy Kids</div>
                  </CustomText>
                </PerksList>
              </PerksWrapper>
            </BeniftsPerks>
            {!result.isSuccess && (
              <>
                <OpeningDate css={{ fontSize: 19.25 }}>
                  KEEP IN TOUCH! OPENING SPRING 2023 IN CULVER CITY
                </OpeningDate>
                <Address
                  css={{
                    marginBottom: '13.25px',
                  }}
                >
                  11924 Washington Blvd, Suite A, Los Angeles CA 90066
                </Address>
              </>
            )}

            {result.isSuccess ? renderSuccessMessage() : preLoadingForm()}
            {result.isError &&
              !isFormTouched &&
              renderErrorMessage('This email address is already registered')}
            <LearnMorePhase3>{renderLearnMoreButton()}</LearnMorePhase3>
          </div>
        </HomeCaptionsPreWrap>
      </HomeSectionsPreWrap>
      <div className="opening-section-mob">
        <HomeCaptionsPreWrap>
          <BeniftsPerks>
            <PerksHeading>MEMBERSHIP benefits and perks</PerksHeading>
            <PerksWrapper>
              <PerksList className="align-center">
                <PngIcon
                  width="100%"
                  src="membership-yoga.png"
                  alt="membership-yoga"
                />
                <CustomText>
                  Unlimited yoga classes<div> per month</div>
                </CustomText>
              </PerksList>

              <PerksList className="align-center">
                <PngIcon
                  width="100%"
                  src="membership-cancel.png"
                  alt="membership-cancel"
                />
                <CustomText>
                  No obligation, month to<div> month, cancel anytime</div>
                </CustomText>
              </PerksList>
              <PerksList className="align-center">
                <PngIcon
                  width="100%"
                  src="membership-credits.png"
                  alt="membership-credits"
                />
                <CustomText>
                  Get 3 guest credits per
                  <div>month to share with friends</div>
                </CustomText>
              </PerksList>
              <PerksList className="align-center">
                <PngIcon
                  width="100%"
                  src="membership-gift.png"
                  alt="membership-gift"
                />
                <CustomText>
                  Exclusive Brightside member
                  <div> gift (provided at your first class)</div>
                </CustomText>
              </PerksList>
              <PerksList className="align-center">
                <PngIcon
                  width="100%"
                  src="membership-percentage.png"
                  alt="membership-percentage"
                />
                <CustomText>10% off retail items</CustomText>
              </PerksList>
              <PerksList className="align-start">
                <PngIcon
                  width="100%"
                  src="membership-heart.png"
                  alt="membership-heart"
                />
                <CustomText>
                  For every membership sold, Brightside will help provide an
                  <div>hour of physical activity to a child</div> in an
                  underserved community{' '}
                  <div>through Action for Healthy Kids</div>
                </CustomText>
              </PerksList>
            </PerksWrapper>
          </BeniftsPerks>
          {!result.isSuccess && (
            <>
              <OpeningDate css={{ fontSize: 19.25 }}>
                KEEP IN TOUCH! OPENING SPRING 2023 IN CULVER CITY
              </OpeningDate>
              <Address
                css={{
                  marginBottom: '13.25px',
                }}
              >
                11924 Washington Blvd, Suite A, Los Angeles CA 90066
              </Address>
            </>
          )}

          {result.isSuccess ? renderSuccessMessage() : preLoadingForm()}
          {result.isError &&
            !isFormTouched &&
            renderErrorMessage('This email address is already registered')}
          <LearnMorePhase3>{renderLearnMoreButton()}</LearnMorePhase3>
        </HomeCaptionsPreWrap>
      </div>
    </>
  );

  // eslint-disable-next-line consistent-return
  const screenLoader = () => {
    if (result.isLoading) {
      return <FullPageLoader />;
    }
  };

  return (
    <>
      {screenLoader()}
      <Meta titleSuffix="Learn More" />
      <HeaderNavigation ispreOpening showJoinTheTeamButton />
      <div>
        {preOpeningPhaseNumber === 1 && renderPhase1PreliveSection()}
        {preOpeningPhaseNumber === 2 && renderPhase2PreliveSection()}
        {preOpeningPhaseNumber === 3 && renderPhase3PreliveSection()}

        <HomeSectionsPreWrap
          id="infraredPage"
          zindex="4"
          imgUrl="heroSlide2preOpening.png"
        >
          <ImgWrapperPre>
            <PngIcon width="100%" src="YogaRoommob.png" />
          </ImgWrapperPre>
          <HomeCaptionsSubPreWrap>
            <HideSmallScreens>
              <SvgIcon
                height={69.25}
                src="heroSlideIcon2new.svg"
                alt="images"
              />
            </HideSmallScreens>
            <ShowSmallScreens>
              <SvgIcon height={40} src="heroSlideIcon2new.svg" alt="images" />
            </ShowSmallScreens>
            <GradientCaptionPre notGradient color="#F4C9FF">
              infrared
            </GradientCaptionPre>
            <GradientCaptionNegativePre notGradient color="#F4C9FF">
              yoga
            </GradientCaptionNegativePre>
            {/* <HideMobile> */}
            <SubCaption css={{ maxWidth: '490px', color: '#152542' }}>
              Our yoga room is supercharged with infrared heat which penetrates
              deep into your body to help detoxify, increase metabolism, boost a
              healthy immune system, alleviate joint and muscle pain, and much
              more!
            </SubCaption>
            {/* </HideMobile> */}
            {/* <HideDesktop> */}
            {/* <SubCaption
                css={{
                  width: '99%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                Our yoga room is supercharged with infrared heat which
                penetrates deep into your body to increase help detoxify,
                metabolism, boost a healthy immune system, alleviate joint and
                muscle pain and much more!
              </SubCaption>
            </HideDesktop> */}
            <StyledLinkButton
              small
              css={{
                marginLeft: '0',
                cursor: 'pointer',
                paddingLeft: 15,
                paddingRight: 15,
                width: '100%',
              }}
              href="/classes"
              target="_self"
              rel="noreferrer"
            >
              learn more about our classes
            </StyledLinkButton>
            {/* </MobileScreenSauna> */}
          </HomeCaptionsSubPreWrap>
        </HomeSectionsPreWrap>

        <HomeSectionsPreWrap
          className="margin-top9"
          zindex="3"
          imgUrl="heroSlide3preOpening.png"
          // mImgUrl="Sauna1mob.png"
          css={{ width: '100%' }}
        >
          <ImgWrapperPre>
            <PngIcon width="100%" src="Sauna1mob.png" />
          </ImgWrapperPre>
          <HomeCaptionsSubPreWrap right>
            <HideSmallScreens>
              <SvgIcon
                height={69.25}
                src="heroSlideIcon3new.svg"
                alt="images"
              />
            </HideSmallScreens>
            <ShowSmallScreens>
              <SvgIcon height={40} src="heroSlideIcon3new.svg" alt="images" />
            </ShowSmallScreens>
            <TextCenter>
              <GradientCaptionPre
                notGradient
                color="#FFC9C9"
                css={{ width: 'auto' }}
                // css={{
                //   width: '100%',
                //   display: 'flex',
                //   flexDirection: 'column',
                //   alignItems: 'flex-end',
                // }}
              >
                infrared
              </GradientCaptionPre>
              <GradientCaptionNegativePre
                notGradient
                color="#FFC9C9"
                css={{ width: 'auto' }}
              >
                saunas
              </GradientCaptionNegativePre>
            </TextCenter>
            {/* <HideMobile> */}
            <SubCaption
              css={{
                maxWidth: '461px',
                color: '#152542',
              }}
            >
              Private suites with mood-mending chromo-therapy lights and the
              ability to listen to your favorite music, podcasts or meditation
              right from your own phone. Relax and get all the benefits of
              infrared while you sit and sweat!
            </SubCaption>
            {/* </HideMobile> */}
            {/* <HideDesktop>
              <SubCaption
                css={{
                  width: '99%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                Private suites with mood-mending chromo- therapy lights and the
                ability to listen to your favorite music, podcasts or meditation
                right from your own phone. Relax and get all the benefits of
                infrared while you sit and sweat!
              </SubCaption>
            </HideDesktop> */}
            <StyledLinkButton
              small
              css={{
                width: 200,
                cursor: 'pointer',
                marginLeft: 'unset !important',
                marginRight: 'unset',
              }}
              href="/what-is-infrared"
              target="_self"
              rel="noreferrer"
            >
              learn more
            </StyledLinkButton>
          </HomeCaptionsSubPreWrap>
        </HomeSectionsPreWrap>

        <HomeSectionsPreWrap
          zindex="2"
          imgUrl="home4preOpening.png"
          customHeight="1020px"
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            width: '100%',
            backgroundPosition: 'center',
          }}
        >
          <ImgWrapperPre>
            <PngIcon width="100%" src="home-mob-4preOpening.png" />
          </ImgWrapperPre>

          <HomeCaptionsSubPreWrap bgPush="0">
            <HideSmallScreens>
              <SvgIcon height={66} src="heroSlideIcon4new.svg" alt="images" />
            </HideSmallScreens>
            <ShowSmallScreens>
              <SvgIcon height={40} src="heroSlideIcon4new.svg" alt="images" />
            </ShowSmallScreens>
            <div css={{ maxWidth: 581 }}>
              <SolidCaption color="#F4C9FF">
                you’ll clap
                <br />
                for our app
              </SolidCaption>
              <HideMobile>
                <SubCaption css={{ color: '#152542' }}>
                  Book, pay, check in, purchase refreshments,
                  <br />
                  track your progress, celebrate milestones
                  <br />
                  and more. Don’t fret, just show up and sweat!
                </SubCaption>
              </HideMobile>
              <HideDesktop>
                <SubCaption css={{ color: '#152542' }}>
                  Book, pay, check in, and more. <br /> Don’t fret, just show up
                  and sweat!
                </SubCaption>
              </HideDesktop>
              {/* Removing the text me app feature for now */}
              {/* <TextMeTheApp /> */}
            </div>
          </HomeCaptionsSubPreWrap>
        </HomeSectionsPreWrap>
        <Footer large ispreOpening />
      </div>
    </>
  );
};

export default PreOpening;
