import { QueryConfig, useMutation } from 'react-query';

import { ErrorType } from '@models/common/async-hook';
import { PreOpeningPayload } from '@models/pre-opening';
import { MutationResponse } from '@models/react-query';
import { client } from '@services/api-client';

const usePreOpeningSubmission = (
  config: QueryConfig<PreOpeningPayload, ErrorType | null> = {},
): MutationResponse<PreOpeningPayload> =>
  useMutation(
    (payload: PreOpeningPayload) =>
      client('pre-opening', {
        method: 'POST',
        data: payload,
        useAPIUrl: false,
      }),
    { ...config },
  );

export { usePreOpeningSubmission };
